import React, { useState } from "react";
import { Animated } from "react-animated-css";
import tourismPhoto from "../../assets/photos/tourism3.jpeg";
import agribusinessPhoto from "../../assets/photos/agribusiness2.jpeg";
import miningPhoto from "../../assets/photos/Mining.jpg";
import lightPhoto from "../../assets/photos/light-industry2.jpeg";
import ictPhoto from "../../assets/photos/ICT2.jpeg";
import infrastructurePhoto from "../../assets/photos/Infrastructure.jpg";
import ToursimModal from "../modal/TourismModal";
import AgriModal from "../modal/AgriModal";
import MiningModal from "../modal/MiningModal";
import ICTModal from "../modal/ICTModal";
import InfraModal from "../modal/InfraModal";
import LightModal from "../modal/LightModal";

const Investment = () => {
  const [tourism, setTourism] = useState(false);
  const [agri, setAgri] = useState(false);
  const [mining, setMining] = useState(false);
  const [light, setLight] = useState(false);
  const [ict, setIct] = useState(false);
  const [infrastructure, setInfrastructure] = useState(false);

  return (
    <div id="investment">
      <h2 className="lg:text-3xl text-center lg:mt-12 lg:mb-4 mt-12 mb-4 font-bold text-2xl uppercase">
        Business Opportunities
      </h2>
      <p className="text-center mx-auto text-md w-2/3 lg:w-full lg:text-xl mb-12">
        We facilitate to set up all types of business in Madagascar
      </p>
      <section className="mb-32 md:mx-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
        <div className="group hover:-translate-y-5 cursor-pointer hover:transition-all hover:duration-200 shadow-md hover:bg-primary hover:shadow-2xl text-gray-900 hover:text-white">
          <div className="relative h-5/6">
            <img
              className="object-cover h-full w-full"
              src={tourismPhoto}
              alt="Tourism"
            />
            <Animated
              animationIn="fadeInDown"
              animationOut="zoomOutDown"
              animationInDuration={4000}
              animationOutDuration={1800}
              isVisible={true}
            >
              <button
                className="hidden group-hover:block bg-white text-primary px-4 py-2 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                onClick={() => setTourism(true)}
              >
                Learn More
              </button>
            </Animated>
          </div>
          <div className="py-7 h-1/6">
            <p className="font-bold text-center">Tourism</p>
          </div>
        </div>
        <div className="group hover:-translate-y-5 cursor-pointer hover:transition-all hover:duration-200 shadow-md hover:bg-primary hover:shadow-2xl text-gray-900 hover:text-white">
          <div className="relative h-5/6">
            <img
              className="object-cover h-full w-full"
              src={agribusinessPhoto}
              alt="Agribusiness"
            />
            <Animated
              animationIn="fadeInDown"
              animationOut="zoomOutDown"
              animationInDuration={4000}
              animationOutDuration={1800}
              isVisible={true}
            >
              <button
                className="hidden group-hover:block bg-white text-primary px-4 py-2 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                onClick={() => setAgri(true)}
              >
                Learn More
              </button>
            </Animated>
          </div>
          <div className="py-7 h-1/6">
            <p className="font-bold text-center">Agribusiness</p>
          </div>
        </div>
        <div className="group hover:-translate-y-5 cursor-pointer hover:transition-all hover:duration-200 shadow-md hover:bg-primary hover:shadow-2xl text-gray-900 hover:text-white">
          <div className="relative h-5/6">
            <img
              className="object-cover h-full w-full"
              src={miningPhoto}
              alt="Mining"
            />
            <Animated
              animationIn="fadeInDown"
              animationOut="zoomOutDown"
              animationInDuration={4000}
              animationOutDuration={1800}
              isVisible={true}
            >
              <button
                className="hidden group-hover:block bg-white text-primary px-4 py-2 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                onClick={() => setMining(true)}
              >
                Learn More
              </button>
            </Animated>
          </div>
          <div className="py-7 h-1/6">
            <p className="font-bold text-center">Mining</p>
          </div>
        </div>
        <div className="group hover:-translate-y-5 cursor-pointer hover:transition-all hover:duration-200 shadow-md hover:bg-primary hover:shadow-2xl text-gray-900 hover:text-white">
          <div className="relative h-5/6">
            <img
              className="object-cover h-full w-full"
              src={ictPhoto}
              alt="ICT"
            />
            <Animated
              animationIn="fadeInDown"
              animationOut="zoomOutDown"
              animationInDuration={4000}
              animationOutDuration={1800}
              isVisible={true}
            >
              <button
                className="hidden group-hover:block bg-white text-primary px-4 py-2 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                onClick={() => setIct(true)}
              >
                Learn More
              </button>
            </Animated>
          </div>
          <div className="py-7 h-1/6">
            <p className="font-bold text-center">
              Information &amp; Communication Technology
            </p>
          </div>
        </div>
        <div className="group hover:-translate-y-5 cursor-pointer hover:transition-all hover:duration-200 shadow-md hover:bg-primary hover:shadow-2xl text-gray-900 hover:text-white">
          <div className="relative h-5/6">
            <img
              className="object-cover h-full w-full"
              src={lightPhoto}
              alt="Light Industry"
            />
            <Animated
              animationIn="fadeInDown"
              animationOut="zoomOutDown"
              animationInDuration={4000}
              animationOutDuration={1800}
              isVisible={true}
            >
              <button
                className="hidden group-hover:block bg-white text-primary px-4 py-2 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                onClick={() => setLight(true)}
              >
                Learn More
              </button>
            </Animated>
          </div>
          <div className="py-7 h-1/6">
            <p className="font-bold text-center">Light Industry</p>
          </div>
        </div>
        <div className="group hover:-translate-y-5 cursor-pointer hover:transition-all hover:duration-200 shadow-md hover:bg-primary hover:shadow-2xl text-gray-900 hover:text-white">
          <div className="relative h-5/6">
            <img
              className="object-cover h-full w-full"
              src={infrastructurePhoto}
              alt="Infrastructure"
            />
            <Animated
              animationIn="fadeInDown"
              animationOut="zoomOutDown"
              animationInDuration={4000}
              animationOutDuration={1800}
              isVisible={true}
            >
              <button
                className="hidden group-hover:block bg-white text-primary px-4 py-2 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                onClick={() => setInfrastructure(true)}
              >
                Learn More
              </button>
            </Animated>
          </div>
          <div className="py-7 h-1/6">
            <p className="font-bold text-center">Infrastructure</p>
          </div>
        </div>
      </section>
      <ToursimModal onClose={() => setTourism(false)} show={tourism} />
      <AgriModal onClose={() => setAgri(false)} show={agri} />
      <MiningModal onClose={() => setMining(false)} show={mining} />
      <ICTModal onClose={() => setIct(false)} show={ict} />
      <InfraModal
        onClose={() => setInfrastructure(false)}
        show={infrastructure}
      />
      <LightModal onClose={() => setLight(false)} show={light} />
    </div>
  );
};

export default Investment;
