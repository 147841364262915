import React from "react";

const InfraModal = (props) => {
  if (!props.show) {
    return null;
  }

  return (
    <div
      onClick={props.onClose}
      className="fixed top-0 left-0 bottom-0 right-0 bg-gray-500 opacity-90 flex justify-center items-center"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="w-[500px] opacity-100 bg-white"
      >
        <div className="px-6 py-3">
          <h4 className="font-bold uppercase">Infrastructure</h4>
        </div>
        <div className="text-justify m-0 px-6 py-3 border-t-2 border-b-2">
          There are opportunities in major infrastructure projects such as
          roads, bridges, hospitals, schools, disaster recovery preparations,
          investment in business parks, development and construction of smart
          cities. High-value projects are usually awarded to large foreign
          construction companies through their well-established Malagasy
          subsidiaries.
        </div>
        <div className="px-6 py-3 bg-primary text-white">
          <button onClick={props.onClose}>Close</button>
        </div>
      </div>
    </div>
  );
};

export default InfraModal;
