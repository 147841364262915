import React from "react";

const LightModal = (props) => {
  if (!props.show) {
    return null;
  }

  return (
    <div
      onClick={props.onClose}
      className="fixed top-0 left-0 bottom-0 right-0 bg-gray-500 opacity-90 flex justify-center items-center"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="w-[500px] opacity-100 bg-white"
      >
        <div className="px-6 py-3">
          <h4 className="font-bold uppercase">Light Industry</h4>
        </div>
        <div className=" text-justify m-0 px-6 py-3 border-t-2 border-b-2">
          Madagascar has an important local resource supply base for the
          development of the world class spinning and weaving operations, the
          plant-based fiber products and other processed products of vegetable
          and animal origin. The investors will benefit from the incentive plan
          of free zones and companies with tax and customs exemptions, the
          competitive labor cost, the highly skilled workforce with a reputation
          of exceptional dexterity and productivity, and the access to regional
          and international markets.
        </div>
        <div className="px-6 py-3 bg-primary text-white">
          <button onClick={props.onClose}>Close</button>
        </div>
      </div>
    </div>
  );
};

export default LightModal;
