import React from "react";
import Investment from "../../components/investment/Investment";
import Tours from "../../components/tours/Tours";

const Home = () => {
  return (
    <section>
      <Tours />
      <Investment />
    </section>
  );
};

export default Home;
