import React from "react";

const ToursimModal = (props) => {
  if (!props.show) {
    return null;
  }

  return (
    <div
      onClick={props.onClose}
      className="fixed top-0 left-0 bottom-0 right-0 bg-gray-500 opacity-90 flex justify-center items-center"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="w-[500px] opacity-100 bg-white"
      >
        <div className="px-6 py-3">
          <h4 className="font-bold uppercase">Tourism</h4>
        </div>
        <div className="text-justify m-0 px-6 py-3 border-t-2 border-b-2">
          Madagascar is uniquely positioned as a high end ecotourism
          destination. With large properties of available land ideally suited
          for tourism, Madagascar offers investors a suitable environment to
          invest in strategic tourism ventures. With the objective of 500 000
          visitors per year, there is a need of new 10 000 rooms supply with
          international standards.
        </div>
        <div className="px-6 py-3 bg-primary text-white">
          <button onClick={props.onClose}>Close</button>
        </div>
      </div>
    </div>
  );
};

export default ToursimModal;
