import React from "react";

const WondersModal = (props) => {
  if (!props.show) {
    return null;
  }

  return (
    <div
      onClick={props.onClose}
      className="fixed top-0 left-0 bottom-0 right-0 bg-gray-500 opacity-90 flex justify-center items-center"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="w-[500px] opacity-100 bg-white"
      >
        <div className="px-6 py-3">
          <h4 className="font-bold uppercase">Vezo Tour</h4>
        </div>
        <div className="m-0 px-6 py-3 border-t-2 border-b-2">
          <p>
            <span className="font-semibold">LOCATION :</span> Southern of
            Madagascar
          </p>
          <p>
            <span className="font-semibold">DURATION :</span> 10 DAYS AND 9
            NIGHTS
          </p>
          <div>
            <p className="font-semibold">ITINERARY :</p>
            <ul className="list-disc list-inside">
              <li>Day 1 : Antananarivo</li>
              <li>Day 2 : Antananarivo - Antsirabe</li>
              <li>Day 3 : Antsirabe - Ambositra - Ranomafana National Park</li>
              <li>Day 4 : Ranomafana National Park</li>
              <li>Day 5 : Ranomafana - Amabalavao - Isalo National Park</li>
              <li>Day 6 : Isalo National Park</li>
              <li>Day 7 : Isalo National Park - Toliary - Ifaty</li>
              <li>Day 8: Ifaty</li>
              <li>Day 9 : Ifaty - Toliary - Antananarivo</li>
              <li>Day 10 : Antananarivo - Out</li>
            </ul>
          </div>{" "}
        </div>
        <div className="px-6 py-3 bg-primary text-white">
          <button onClick={props.onClose}>Close</button>
        </div>
      </div>
    </div>
  );
};

export default WondersModal;
