import React from "react";

const AgriModal = (props) => {
  if (!props.show) {
    return null;
  }

  return (
    <div
      onClick={props.onClose}
      className="fixed top-0 left-0 bottom-0 right-0 bg-gray-500 opacity-90 flex justify-center items-center"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="w-[500px] opacity-100 bg-white"
      >
        <div className="px-6 py-3">
          <h4 className="font-bold uppercase">Agri-business</h4>
        </div>
        <div className="text-justify m-0 px-6 py-3 border-t-2 border-b-2">
          Madagascar, the world’s leading exporter of vanilla and cloves, has
          around 30 million hectares of agricultural land suitable for several
          types of temperate and tropical products, but only about 10% of the
          mentioned surface is used up to now. There are several business
          opportunities available in the field of agriculture and one good thing
          is that there is market for all the products from the industry.
        </div>
        <div className="px-6 py-3 bg-primary text-white">
          <button onClick={props.onClose}>Close</button>
        </div>
      </div>
    </div>
  );
};

export default AgriModal;
