import React, { useState } from "react";
import BaobabModal from "../modal/BaobabModal";
import IndriModal from "../modal/IndriModal";
import PearlModal from "../modal/PearlModal";
import WondersModal from "../modal/WondersModal";
import { Animated } from "react-animated-css";
import baobab from "../../assets/photos/baobab-tour2.jpeg";
import indri from "../../assets/photos/indri-tour2.jpeg";
import pearl from "../../assets/photos/Pearl.jpg";
import wonders from "../../assets/photos/Wonders.jpg";

const Tour = () => {
  const [showBaobab, setShowBaobab] = useState(false);
  const [showIndri, setShowIndri] = useState(false);
  const [showPearl, setShowPearl] = useState(false);
  const [showWonders, setShowWonders] = useState(false);

  return (
    <>
      <section className="md:mx-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-7 lg:gap-2 lg:h-96">
        <div className="group hover:-translate-y-5 cursor-pointer hover:transition-all hover:duration-200 shadow-md hover:bg-primary hover:shadow-2xl text-gray-900 hover:text-white">
          <div className="relative h-2/3">
            <img
              className="object-cover object-left h-full w-full"
              src={baobab}
              alt="Baobab Tour"
            />
            <Animated
              animationIn="fadeInDown"
              animationOut="zoomOutDown"
              animationInDuration={4000}
              animationOutDuration={1800}
              isVisible={true}
            >
              <button
                className="hidden group-hover:block bg-white text-primary px-4 py-2 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                onClick={() => setShowBaobab(true)}
              >
                Learn More
              </button>
            </Animated>
          </div>
          <div className="px-5 py-5 leading-7">
            <p className="font-bold">Baobab Tour</p>
            <p className="text-sm">Sanctuary of nature and ecological jewel</p>
            <p className="font-bold">From $950</p>
          </div>
        </div>
        <div className="group hover:-translate-y-5 cursor-pointer hover:transition-all hover:duration-200 shadow-md hover:bg-primary hover:shadow-2xl text-gray-900 hover:text-white">
          <div className="relative h-2/3">
            <img
              className="object-cover h-full w-full"
              src={indri}
              alt="Indri Tour"
            />
            <Animated
              animationIn="fadeInDown"
              animationOut="zoomOutDown"
              animationInDuration={4000}
              animationOutDuration={1800}
              isVisible={true}
            >
              <button
                className="hidden group-hover:block bg-white text-primary px-4 py-2 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                onClick={() => setShowIndri(true)}
              >
                Learn More
              </button>
            </Animated>
          </div>
          <div className="px-5 py-5 leading-7">
            <p className="font-bold">Indri Tour</p>
            <p className="text-sm">Dense humid forest</p>
            <p className="font-bold">From $1075</p>
          </div>
        </div>
        <div className="group hover:-translate-y-5 cursor-pointer hover:transition-all hover:duration-200 shadow-md hover:bg-primary hover:shadow-2xl text-gray-900 hover:text-white">
          <div className="relative h-2/3">
            <img
              className="object-cover h-full w-full"
              src={pearl}
              alt="Sakalava Tour"
            />
            <Animated
              animationIn="fadeInDown"
              animationOut="zoomOutDown"
              animationInDuration={4000}
              animationOutDuration={1800}
              isVisible={true}
            >
              <button
                className="hidden group-hover:block bg-white text-primary px-4 py-2 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                onClick={() => setShowPearl(true)}
              >
                Learn More
              </button>
            </Animated>
          </div>
          <div className="px-5 py-5 leading-7">
            <p className="font-bold">Sakalava Tour</p>
            <p className="text-sm">Most beautiful beaches of Madagascar</p>
            <p className="font-bold">From $1750</p>
          </div>
        </div>
        <div className="group hover:-translate-y-5 cursor-pointer hover:transition-all hover:duration-200 shadow-md hover:bg-primary hover:shadow-2xl text-gray-900 hover:text-white">
          <div className="relative h-2/3">
            <img
              className="object-cover h-full w-full"
              src={wonders}
              alt="Vezo Tour"
            />
            <Animated
              animationIn="fadeInDown"
              animationOut="zoomOutDown"
              animationInDuration={4000}
              animationOutDuration={1800}
              isVisible={true}
            >
              <button
                className="hidden group-hover:block bg-white text-primary px-4 py-2 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                onClick={() => setShowWonders(true)}
              >
                Learn More
              </button>
            </Animated>
          </div>
          <div className="px-5 py-5 leading-7">
            <p className="font-bold">Vezo Tour</p>
            <p className="text-sm">Unique scenery, fauna and flora</p>
            <p className="font-bold">From $1675</p>
          </div>
        </div>
      </section>
      <BaobabModal onClose={() => setShowBaobab(false)} show={showBaobab} />
      <IndriModal onClose={() => setShowIndri(false)} show={showIndri} />
      <PearlModal onClose={() => setShowPearl(false)} show={showPearl} />
      <WondersModal onClose={() => setShowWonders(false)} show={showWonders} />
    </>
  );
};

export default Tour;
