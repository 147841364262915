import React from "react";

const ICTModal = (props) => {
  if (!props.show) {
    return null;
  }

  return (
    <div
      onClick={props.onClose}
      className="fixed top-0 left-0 bottom-0 right-0 bg-gray-500 opacity-90 flex justify-center items-center"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="w-[500px] opacity-100 bg-white"
      >
        <div className="px-6 py-3">
          <h4 className="font-bold uppercase">
            Information &amp; Communication Technology
          </h4>
        </div>
        <div className="text-justify m-0 px-6 py-3 border-t-2 border-b-2">
          A booming infrastructure network connecting Madagascar to the rest of
          the world by submarine fiber optics, very short time difference
          beneficial for call centers, qualified and talented workforce speaking
          French and English without accent, and supply of well-educated IT
          experts, make the country an overwhelming destination for ICT
          investment project.
        </div>
        <div className="px-6 py-3 bg-primary text-white">
          <button onClick={props.onClose}>Close</button>
        </div>
      </div>
    </div>
  );
};

export default ICTModal;
