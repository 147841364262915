import React from "react";
import Tour from "../toursBis/Tour";

const Tours = () => {
  return (
    <div className="lg:mx-10 mb-12" id="tours">
      <h2 className="lg:text-3xl text-center lg:mt-12 lg:mb-4 mt-12 mb-4 font-bold text-2xl uppercase">
        Tour packages
      </h2>
      <p className="text-center mx-auto text-md w-2/3 lg:w-full lg:text-xl mb-12">
        Madagascar is a unique getaway destination in the world
      </p>
      <Tour />
      {/* <div className="flex lg:flex-row flex-col gap-3 justify-center items-center lg:gap-8">
        <button
          onClick={() => {
            setIsBaobab(true);
            setIsIndri(false);
            setIsPearl(false);
            setIsWonder(false);
          }}
          className={`${
            isBaobab ? "bg-primary text-white" : null
          } w-64 py-2  hover:bg-primary hover:text-white border-2 border-primary text-primary lg:py-2 lg:px-4`}
        >
          Baobab Tour
        </button>
        <button
          onClick={() => {
            setIsBaobab(false);
            setIsIndri(true);
            setIsPearl(false);
            setIsWonder(false);
          }}
          className={`${
            isIndri ? "bg-primary text-white" : null
          }  w-64 py-2 hover:bg-primary hover:text-white border-2 border-primary text-primary lg:py-2 lg:px-4`}
        >
          Indri Tour
        </button>
        <button
          onClick={() => {
            setIsBaobab(false);
            setIsIndri(false);
            setIsPearl(true);
            setIsWonder(false);
          }}
          className={`${
            isPearl ? "bg-primary text-white" : null
          }  w-64 py-2 hover:bg-primary hover:text-white border-2 border-primary text-primary lg:py-2 lg:px-4`}
        >
          Pearls Tour
        </button>
        <button
          onClick={() => {
            setIsBaobab(false);
            setIsIndri(false);
            setIsPearl(false);
            setIsWonder(true);
          }}
          className={`${
            isWonder ? "bg-primary text-white" : null
          } w-64 py-2  hover:bg-primary hover:text-white border-2 border-primary text-primary lg:py-2 lg:px-4`}
        >
          Wonders Tour
        </button>
      </div>
      {isBaobab && (
        <TourInfo
          tourTitle="Baobab"
          tourDescription={descriptionOfTours.baobab}
          tourDuration="7d/6n"
          tourPrice="950"
          tourImage={baobab}
        />
      )}
      {isIndri && (
        <TourInfo
          tourTitle="Indri"
          tourDescription={descriptionOfTours.indri}
          tourDuration="8d/7n"
          tourPrice="1075"
          tourImage={indri}
        />
      )}
      {isPearl && (
        <TourInfo
          tourTitle="Pearls"
          tourDescription={descriptionOfTours.pearl}
          tourDuration="8d/7n"
          tourPrice="1750"
          tourImage={pearls}
        />
      )}
      {isWonder && (
        <TourInfo
          tourTitle="Wonders"
          tourDescription={descriptionOfTours.wonder}
          tourDuration="10d/9n"
          tourPrice="1675"
          tourImage={wonders}
        />
      )} */}
    </div>
  );
};

export default Tours;
