import React from "react";

const MiningModal = (props) => {
  if (!props.show) {
    return null;
  }

  return (
    <div
      onClick={props.onClose}
      className="fixed top-0 left-0 bottom-0 right-0 bg-gray-500 opacity-90 flex justify-center items-center"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="w-[500px] opacity-100 bg-white"
      >
        <div className="px-6 py-3">
          <h4 className="font-bold uppercase">Mining</h4>
        </div>
        <div className="text-justify m-0 px-6 py-3 border-t-2 border-b-2">
          Madagascar is member of the Extractive Industries Transparency
          Initiative (EITI), and known for the opulence of its subsoil,
          resulting in the development of both small mining sectors
          (hand-crafted) and large mining ones. Many untapped reserves of
          minerals containing industrial and metallic minerals, energy resources
          (coal, hydrocarbon, uranium, …), precious and semi-precious stones
          (ruby, sapphire, emerald, aquamarine, beryl, tourmaline, topaz,
          garnet, cordierite, rose quartz, amethyst, citrine, …), ornamental
          stones (marble, silicified wood, jasper, …) are available to be
          exploited for economic benefits.
        </div>
        <div className="px-6 py-3 bg-primary text-white">
          <button onClick={props.onClose}>Close</button>
        </div>
      </div>
    </div>
  );
};

export default MiningModal;
