import React from "react";

const Hero = () => {
  return (
    <div
      id="hero"
      className="lg:p-20 bg-hero-pattern bg-cover bg-no-repeat bg-fixed h-screen flex justify-center items-center lg:justify-end lg:items-center"
    >
      <div className="w-10/12 bg-slate-200 lg:p-10 p-5 opacity-80">
        <h1 className="text-center lg:text-5xl lg:mb-4 text-3xl mb-2">
          Inbound and outbound
          <span className="text-primary"> tour operator</span>{" "}
        </h1>
        <p className="lg:text-xl bg-clip-text text-center bg-gradient-to-r from-orange-400 text-primary">
          Promotion of investment in Madagascar
        </p>
      </div>
    </div>
  );
};

export default Hero;
